// ThemeContext.js
import React, { createContext, useContext, useState } from 'react'

const FontContext = createContext()

export const FontProvider = ({ children }) => {
  const storedUserString = localStorage.getItem('themeData')
  const storedUserObject = JSON.parse(storedUserString)

  // Check if storedUserObject is null or undefined

  const initialFontStyle = storedUserObject?.fontStyle || 'arial'
  const initialFontSize = storedUserObject?.fontSize || 'small'
  const [fontStyle, setFontStyle] = useState(initialFontStyle)
  const [fontSize, setFontSize] = useState(initialFontSize)

  const updateFontStyle = (newFontStyle) => {
    console.log(newFontStyle, 'newFontStyle value here')
    setFontStyle(newFontStyle)
    // Additional logic if needed
  }

  const updateFontSize = (newFontSize) => {
    console.log(newFontSize, 'newFontSize value here')
    setFontSize(newFontSize)
    // Additional logic if needed
  }

  return (
    <FontContext.Provider
      value={{
        fontStyle,
        fontSize,
        updateFontStyle,
        updateFontSize,
      }}
    >
      {children}
    </FontContext.Provider>
  )
}

export const useFont = () => {
  const context = useContext(FontContext)
  if (!context) {
    throw new Error('useFont must be used within a FontProvider')
  }
  return context
}
