import { ActionType } from "../constant/actionType";
import profile from '../../assets/images/person.png'
let info = localStorage.getItem("img_url");

const initialState = {
  image: info?info:profile,
};

export const getFlag = (state = initialState, { type, payload }) => {
   let paloads=payload
  switch (type) {
    case ActionType.SET_FLAG:
      return { ...state, image: paloads };
    default:
      return state;
  }
}
