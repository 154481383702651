// ThemeContext.js
import React, { createContext, useContext, useState } from 'react'

const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const storedUserString = localStorage.getItem('themeData')
  const storedUserObject = JSON.parse(storedUserString)

  // Check if storedUserObject is null or undefined
  const initialColourTheme = storedUserObject?.orgTheme || 'defaultColourTheme'
  const initialBackgroundColorTheme = storedUserObject?.backgroundColor || 'defaultBackgroundColor'
  const initialButtonThemeColor = storedUserObject?.buttonColor || 'defaultButtonColor'
  const initialTextTheme = storedUserObject?.textColor || 'defaultTextColor'

  const [colourTheme, setColourTheme] = useState(initialColourTheme)
  const [backgroundColorTheme, setBackgroundColorTheme] = useState(initialBackgroundColorTheme)
  const [buttonThemeColor, setButtonTheme] = useState(initialButtonThemeColor)
  const [textTheme, setTextTheme] = useState(initialTextTheme)

  const updateTheme = (newTheme) => {
    setColourTheme(newTheme)
    // Additional logic if needed
  }

  const updateBackgroundTheme = (newTheme) => {
    setBackgroundColorTheme(newTheme)
    // Additional logic if needed
  }

  const updateButtonTheme = (newTheme) => {
    setButtonTheme(newTheme)
    // Additional logic if needed
  }

  const updateTextTheme = (newTheme) => {
    setTextTheme(newTheme)
    // Additional logic if needed
  }

  return (
    <ThemeContext.Provider
      value={{
        colourTheme,
        backgroundColorTheme,
        buttonThemeColor,
        textTheme,
        updateTheme,
        updateBackgroundTheme,
        updateButtonTheme,
        updateTextTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}
