// index.js

import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AuthProvider } from './AuthContext'
import store from './redux/store'
import { Provider } from 'react-redux'
import { ThemeProvider } from './views/organization/ThemeContext'
import { FontProvider } from './views/organization/FontContext'

const root = createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <ToastContainer />
    <AuthProvider>
      <FontProvider>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </FontProvider>
    </AuthProvider>
  </Provider>,
)

reportWebVitals()
